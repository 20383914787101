import React from 'react';
import styled from 'styled-components';
import themeGet from '@styled-system/theme-get'

import Image from './Image'
import Box from './Box'

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const StyledSlider = styled(Slider)`
.slick-prev:before,
.slick-next:before {
  color: ${themeGet('colors.text')};
}
`

const ImageGallery = ({ images, ...props }) => {
  if (!images || !images.length) return null
  return (
    <Box {...props}>
      <StyledSlider dots>
        {images.map((src, i)=> (
          <Box key={i}>
            <Image src={src} alt={src} />
          </Box>
        ))}
      </StyledSlider>
    </Box>
  );
}

export default ImageGallery;
